// src/Routes.js
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"; // Importe o componente Routes

import HomePage from "./pages/HomePage";
import MyCard from "./pages/MyCard";
import MyCart from "./pages/MyCart";
import ProductDetails from "./pages/ProductDetails";
import { CartProvider } from "./contexts/CartContext";
import CodeTable from "./pages/CodeTable";
import { ProductProvider } from "./contexts/ProductContext";
import ClientData from "./pages/ClientData";
import DeliveryData from "./pages/DeliveryData";
import LoginDelivery from "./pages/LoginDelivery";

const AppRoutes = () => {
    return (
        <CartProvider>
            <ProductProvider >
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/carrinho" element={<MyCart />} />
                        <Route path="/comanda" element={<MyCard />} />
                        <Route path="/detalhes/*" element={<ProductDetails />} />
                        <Route path="/dadoscliente" element={<ClientData />} />
                        <Route path="/dadosentrega" element={<DeliveryData />} />
                        <Route path="/qrcode/:restaurante/:mesa" element={<CodeTable />} />
                        <Route path="/:restaurante" element={<LoginDelivery />} />
                    </Routes>
                </BrowserRouter>
            </ProductProvider>
        </CartProvider>
    );
};

export default AppRoutes;
