import { useState } from "react";
import Navbar from "../components/NavBar.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

function DeliveryData({onDeliveryDataSubmit }) {
    const [cep, setCep] = useState('');
    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [tipoEntrega, setTipoEntrega] = useState();

    const handleCepMask = (e) => {
        let maskedValue = e.replace(/\D/g, '');
        if (maskedValue.length > 8) {
            maskedValue = maskedValue.substring(0, 8);
        }
        if (maskedValue.length > 5) {
            maskedValue = maskedValue.replace(/^(\d{5})(\d)/, '$1-$2');
        }
        if (maskedValue.length === 9) {
            buscarEnderecoPorCep(maskedValue.replace(/\D/g, ''));
        }
        setCep(maskedValue);
    };

    const buscarEnderecoPorCep = async (cep) => {
        if (cep.length === 8) {
            try {
                const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
                const data = response.data;
                if (!data.erro) {
                    setRua(data.logradouro);
                    setBairro(data.bairro);
                    setCidade(data.localidade);
                    setEstado(data.uf);
                } else {
                    setRua("");
                    setBairro("");
                    setCidade("");
                    setEstado("");
                }
            } catch (error) {
                console.error("Erro ao buscar endereço:", error);
                alert("Erro ao buscar endereço.");
            }
        }
    };

    const handleContinue = () => {
        if (tipoEntrega === "Delivery") {
            if (!cep || !rua || !numero || !bairro || !cidade) {
                alert("Todos os campos devem ser preenchidos corretamente.");
                return;
            }
        }

        const dataToSubmit = {
            tipoEntrega,
            ...(tipoEntrega === "Delivery" && { cep, rua, numero, bairro, cidade, estado })
        };
        
        onDeliveryDataSubmit(dataToSubmit);
    };

    return (
        <>
            <div className="px-5 py-5 pb-20">
                <Navbar typeNav="Delivery" title="Entrega" />
                <div className="flex justify-center">
                    <div className="text-center px-2 mt-5">
                        <span className="text-xl mx-5" style={{ color: '#A8A8A8' }}>Como você prefere: retirada ou entrega?</span>
                        <div className="mt-5 border px-5 py-3 rounded-[10px] cursor-pointer" style={{ borderColor: '#7E7E7E', backgroundColor: '#3D3D3D' }}>
                            <div className="flex justify-between text-white items-center" onClick={() => setTipoEntrega("Retirada")}>
                                <span className="text-lg">Retirar no Balcão</span>
                                <div className="px-2 py-1 rounded-[5px]" style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}>
                                    <FontAwesomeIcon icon={faArrowRight} className='text-xl' />
                                </div>
                            </div>
                            <div
                                className="mt-5 px-10 overflow-hidden transition-all duration-500"
                                style={{
                                    maxHeight: tipoEntrega === "Retirada" ? '100px' : '0',
                                    opacity: tipoEntrega === "Retirada" ? '1' : '0',
                                    padding: tipoEntrega === "Retirada" ? '1rem 1rem 0 1rem' : '0',
                                    margin: tipoEntrega === "Retirada" ? '0' : '0 0',
                                }}>
                                <button className="text-white py-2 text-md rounded-full w-full" style={{ background: 'linear-gradient(to right bottom, #583500, #d17a00' }} onClick={handleContinue}>
                                    Continuar
                                </button>
                            </div>
                        </div>
                        <div className="mt-5 border px-5 py-3 rounded-[10px] cursor-pointer" style={{ borderColor: '#7E7E7E', backgroundColor: '#3D3D3D' }}>
                            <div className="flex justify-between text-white items-center" onClick={() => setTipoEntrega("Delivery")}>
                                <span className="text-lg">Entrega</span>
                                <div className="px-2 py-1 rounded-[5px]" style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}>
                                    <FontAwesomeIcon icon={faArrowRight} className='text-xl' />
                                </div>
                            </div>
                            <div
                                className="space-y-4 mt-2 overflow-hidden transition-all duration-500"
                                style={{
                                    maxHeight: tipoEntrega === "Delivery" ? '500px' : '0',
                                    opacity: tipoEntrega === "Delivery" ? '1' : '0',
                                    padding: tipoEntrega === "Delivery" ? '1rem 0 0 0' : '0',
                                    margin: tipoEntrega === "Delivery" ? '0' : '0 0',
                                }}>
                                <input
                                    type="text"
                                    placeholder="CEP"
                                    value={cep}
                                    onChange={(e) => handleCepMask(e.target.value)}
                                    className="border border-[#A8A8A8] focus:outline-none focus:ring-0 rounded-[10px] p-2 w-full bg-transparent text-white"
                                />
                                <input
                                    type="text"
                                    placeholder="Rua"
                                    value={rua}
                                    onChange={(e) => setRua(e.target.value)}
                                    className="border border-[#A8A8A8] focus:outline-none focus:ring-0 rounded-[10px] p-2 w-full bg-transparent text-white"
                                />
                                <input
                                    type="text"
                                    placeholder="Número"
                                    value={numero}
                                    onChange={(e) => setNumero(e.target.value)}
                                    className="border border-[#A8A8A8] focus:outline-none focus:ring-0 rounded-[10px] p-2 w-full bg-transparent text-white"
                                />
                                <input
                                    type="text"
                                    placeholder="Bairro"
                                    value={bairro}
                                    onChange={(e) => setBairro(e.target.value)}
                                    className="border border-[#A8A8A8] focus:outline-none focus:ring-0 rounded-[10px] p-2 w-full bg-transparent text-white"
                                />
                                <input
                                    type="text"
                                    placeholder="Cidade"
                                    value={cidade}
                                    onChange={(e) => setCidade(e.target.value)}
                                    className="border border-[#A8A8A8] focus:outline-none focus:ring-0 rounded-[10px] p-2 w-full bg-transparent text-white"
                                />
                                <div className="mt-2 px-10">
                                    <button
                                        className="text-white py-2 text-md rounded-full w-full"
                                        style={{ background: 'linear-gradient(to right bottom, #583500, #d17a00' }}
                                        onClick={handleContinue}
                                    >
                                        Continuar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DeliveryData;
