import Navbar from "../components/NavBar.js";
import SearchBar from "../components/SearchBar.js";
import Categories from "../components/Categories.js";
import FloatButton from "../components/FloatButton.js";
import { useEffect, useState } from "react";
import axios from "axios";

// import imgBanner from "../assets/hamburguerBanner.png"; // IMG Banner
// import Banner from "../components/Banner.js";
import CardGroup from "../components/CardGroup.js";
import Loading from "./Loading.js";

// const banners = [
//     { id: 1, badge: "Promoção", titulo: "Dose Dupla.", descricao: "2 Old Burguer por apenas:", valor: "35.50", img: imgBanner }
// ]

function HomePage() {
    const [selectedCategory, setSelectedCategory] = useState();
    const [produtos, setProdutos] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setCategorias(Object.keys(produtos).map((category, index) => ({
            id: index + 1,
            name: category
        })));

        var categoria = {
            id: 1,
            name: Object.keys(produtos)[0]
        }
        setSelectedCategory(categoria)
    }, [produtos]);

    useEffect(() => {
        buscarProdutos();
    }, []);

    async function buscarProdutos(filtro) {
        try {
            setLoading(true);
            const url = filtro ? `Produto/GetProdutos?nome=${filtro}` : 'Produto/GetProdutos';
            const response = await axios.get(url);

            if (response.status === 200) {
                setProdutos(response.data)
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        } finally {
            setLoading(false);
        }
    };

    const onCategorySelect = (category) => {
        setSelectedCategory(category);

        const section = document.getElementById(category.name);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleSearch = (searchTerm) => {
        buscarProdutos(searchTerm);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="px-5 py-5 pb-20">
            <Navbar typeNav="Home" />
            <SearchBar onSearch={handleSearch} />
            {/* {banners.map((banner) => (
                <Banner key={banner.id} badge={banner.badge} titulo={banner.titulo} descricao={banner.descricao} valor={banner.valor} img={banner.img} />
            ))} */}
            {produtos && (
                <div>
                    <Categories categories={categorias} selectedCategory={selectedCategory} onCategorySelect={onCategorySelect} />
                    {Object.keys(produtos).map(categoria => (
                        <CardGroup key={categoria} itens={produtos[categoria]} categoria={categoria} />
                    ))}
                </div>
            )}

            <FloatButton />
        </div>
    );
}

export default HomePage;