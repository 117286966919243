import { useContext, useEffect, useState } from "react";
import Navbar from "../components/NavBar.js";
import Divider from "../components/Divider.js";
import PrimaryButton from "../components/PrimaryButton.js";
import QtdButton from "../components/QtdButton.js";
import Modal from "../components/Modal.js";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { CartContext } from "../contexts/CartContext.js";
import { ProductContext } from "../contexts/ProductContext.js";
import { formatValue } from "../utils/formatValue.js";

function ProductDetails() {
    const [isModalAddCart, setIsModalAddCart] = useState(false);
    const [quantidade, setQuantidade] = useState(1);
    const [observacao, setObservacao] = useState("");

    const { addToCart } = useContext(CartContext);
    const { selectedProduct } = useContext(ProductContext);

    useEffect(() => {
        if (selectedProduct.length === 0) {
            window.location.href = "/";
        }
    }, [selectedProduct]);

    const openModal = () => {
        if (selectedProduct.length !== 0) {
            setIsModalAddCart(true);
        }
    };

    const closeModal = () => {
        setIsModalAddCart(false);
    };

    const adicionarCarrinho = () => {
        selectedProduct.quantidade = quantidade;
        selectedProduct.observacao = observacao;
        addToCart(selectedProduct)
    }

    return (
        <>
            <Modal isOpen={isModalAddCart} onClose={closeModal}>
                <div className="text-white items-center flex text-center justify-center mb-4">
                    <FontAwesomeIcon className="text-4xl mr-4" icon={faCircleCheck} />
                    <span className="text-xl font-semibold">{selectedProduct.titulo} adicionado ao carrinho com sucesso!</span>
                </div>
                <Divider />
                <div className="my-2 text-white flex items-center justify-between">
                    <span className="text-xl font-semibold">Quantidade:</span>
                    <div className="">
                        <QtdButton size="sm" quantidade={quantidade} setQuantidade={setQuantidade} />
                    </div>
                </div>
                <Divider />
                <div className="my-4 text-white">
                    <span className="text-xl font-semibold">Observações:</span>
                    <textarea
                        className="block w-full p-3 rounded-[10px] mt-2 text-white"
                        style={{ backgroundColor: '#3D3D3D', border: '2px solid #7E7E7E' }}
                        placeholder="Ex: Remover cebola."
                        onChange={(e) => setObservacao(e.target.value)}
                        rows={4}
                    />
                </div>
                <div className="mb-4">
                    <Link to={"/carrinho"}>
                        <PrimaryButton text={"Ir para o Carrinho"} onClick={() => adicionarCarrinho()} />
                    </Link>
                </div>
                <div>
                    <Link to={"/"}>
                        <PrimaryButton text={"Voltar para o Cardápio"} onClick={() => adicionarCarrinho()} />
                    </Link>
                </div>
            </Modal>

            <div className="relative">
                <div className="absolute inset-0 z-10 rounded-[50px] md:hidden" style={{ height: '45%', marginTop: '-50px', backgroundColor: 'rgba(217, 217, 217, 0.2)' }}></div>

                <div className="px-5 pt-5 relative z-20">
                    <Navbar typeNav="MinimalLeft" title={selectedProduct.titulo} />
                    <div className="md:flex w-full items-center">
                        <div className="px-8 pt-4 md:w-full lg:w-2/4 xl:w-1/4">
                            <img src={selectedProduct.url_Imagem} alt="Hamburguer" className='w-full h-auto rounded-[10px]' />
                        </div>
                        <div className="md:px-8 pt-4 w-full">
                            <span className='text-4xl md:text-2xl text-white font-semibold block'>{selectedProduct.titulo}</span>
                            <div className="my-4 md:hidden">
                                <Divider />
                            </div>
                            <span className="text-xl md:text-lg font-light" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                                {selectedProduct.descricao}
                            </span>
                            <div className="flex justify-between text-white mt-6 mb-2">
                                <div className="items-center text-center">
                                    <span className="text-2xl md:text-xl">
                                        Quantidade:
                                    </span>
                                    <QtdButton quantidade={quantidade} setQuantidade={setQuantidade} />
                                </div>
                                <div className="flex items-center">
                                    {selectedProduct.emPromocao ?
                                        <div className="flex items-center space-x-2">
                                            <span className="font-light text-md text-white line-through">{formatValue(selectedProduct.valor)}</span>
                                            <span className="text-4xl md:text-3xl font-semibold" style={{ color: '#FB9400' }}>
                                                {formatValue(selectedProduct.valorPromocional)}
                                            </span>
                                        </div>
                                        :
                                        <span className="text-4xl md:text-3xl font-semibold" style={{ color: '#FB9400' }}>
                                            {formatValue(selectedProduct.valor)}
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-4 pb-10 pointer-cursor" onClick={() => openModal()}>
                        <PrimaryButton text={"Adicionar ao Carrinho"} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductDetails;