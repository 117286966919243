import React from 'react';
import Divider from "../components/Divider.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { formatValue } from '../utils/formatValue.js';

const CardValues = ({ obs, children, subtotal, total }) => {
    return (
        <div className="mt-4 w-full rounded-[10px] p-4" style={{ background: 'linear-gradient(to right bottom, #282828, #424242' }}>
            <div className="flex justify-between text-lg" style={{ color: 'rgba(168, 168, 168, 1)' }}>
                <div className="items-center">
                    Subtotal
                </div>
                <div className="items-center">
                    {formatValue(subtotal)}
                </div>
            </div>
            <div className="mt-1 mb-3">
                <Divider />
            </div>
            <div className="flex justify-between text-2xl text-white" >
                <div className="items-center">
                    Total
                </div>
                <div className="items-center">
                    {formatValue(total)}
                </div>
            </div>
            {obs && <div className="mt-4 flex justify-center items-center" style={{ color: '#CCC' }}>
                <FontAwesomeIcon icon={faClock} className='text-md text-white mr-2' />
                <span>{obs}</span>
            </div>
            }
            <div className="mt-4">
                {children}
            </div>
        </div>
    );
};

export default CardValues;