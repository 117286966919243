import { useEffect, useState } from "react";
import Navbar from "../components/NavBar.js";
import PrimaryButton from "../components/PrimaryButton.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import CardProductFlat from "../components/CardProductFlat.js";
import CardValues from "../components/CardValues.js";
import FloatButton from "../components/FloatButton.js";
import Modal from "../components/Modal.js";
import Divider from "../components/Divider.js";
import { Link } from "react-router-dom";
import axios from "axios";
import Loading from "./Loading.js";

function MyCard() {
    const [produtos, setProdutos] = useState([]);
    const [isModalAddCart, setIsModalAddCart] = useState(false);
    const [valorSubtotal, setValorSubtotal] = useState(0);
    const [valorTotal, setValorTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const openModal = () => {
        setIsModalAddCart(true);
    };

    const closeModal = () => {
        setIsModalAddCart(false);
    };

    useEffect(() => {
        buscarPedidosMesa();
    }, []);

    async function buscarPedidosMesa() {
        try {
            setLoading(true);
            const response = await axios.get(`Pedidos/PedidosMesa`);
            if (response.status === 200) {
                let pedidos = response.data;

                setProdutos(pedidos)

                let totalCarrinho = 0;

                pedidos[0].itensPedido.map(pedido => (
                    totalCarrinho += pedido.precoUnitario * pedido.quantidade
                ))

                setValorSubtotal(totalCarrinho);
                setValorTotal(totalCarrinho);
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        } finally {
            setLoading(false);
        }
    };

    async function fecharComanda() {
        try {
            setLoading(true);
            let numMesa = localStorage.getItem('tableNumber');
            if(!numMesa)
                return; // msg erro

            const response = await axios.post(`Pedidos/ChamarGarcom/${numMesa}`);
            if (response.status === 200) {
                openModal();
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <Modal isOpen={isModalAddCart} onClose={closeModal}>
                <div className="text-white items-center flex text-center justify-center mb-4">
                    <FontAwesomeIcon className="text-4xl mr-4" icon={faCircleCheck} />
                    <span className="text-xl font-semibold">Por Favor, Aguarde!</span>
                </div>
                <Divider />
                <div className="my-4 text-white text-center p-4">
                    <span className="text-lg font-normal">Sua requisição foi enviada, um de nossos garçons já irão lhe atender</span>
                </div>
                <div>
                    <Link to={"/"}>
                        <PrimaryButton text={"Voltar para o Cardápio"} />
                    </Link>
                </div>
            </Modal>

            <div className="px-5 pt-5 pb-20">
                <Navbar typeNav="ReturnNav" title="Minha Comanda" />

                {produtos.length === 0 ? (
                    <div className="flex h-96 mt-48 justify-center items-center">
                        <div className="text-center px-6">
                            <FontAwesomeIcon
                                icon={faUtensils}
                                className="text-8xl text-white block w-full"
                                style={{ color: "rgba(168, 168, 168, 1)" }}
                            />
                            <br />
                            <span
                                className="text-white text-center text-xl"
                                style={{ color: "rgba(168, 168, 168, 1)" }}
                            >
                                Oops! Sua comanda ainda está vazia. Explore nosso cardápio e
                                escolha entre uma variedade de pratos deliciosos.
                            </span>
                            <div className="my-4">
                                <PrimaryButton text={"Voltar para o Cardápio"} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        {produtos &&
                            produtos.map(pedido => (
                                pedido && pedido.itensPedido && pedido.itensPedido.length > 0 && 
                                pedido.itensPedido.map(produtoObj => {
                                    produtoObj.produto.quantidade = produtoObj.quantidade;
                                    produtoObj.produto.status = pedido.status;

                                    return <CardProductFlat type={"MyCard"} key={produtoObj.id} produto={produtoObj.produto} />
                                })
                            ))
                        }

                        <CardValues subtotal={valorSubtotal} total={valorTotal}>
                            <PrimaryButton
                                text={"Fechar a comanda"}
                                onClick={() => fecharComanda()}
                            />
                        </CardValues>
                    </div>
                )}

                <FloatButton />
            </div>
        </>
    );
}

export default MyCard;
