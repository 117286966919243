import React from 'react';

const Divider = () => {
    return (
        <div className='my-0'>
            <div className="rounded-full" style={{ height: '2.5px' ,background: 'linear-gradient(to right, #929292 75%, rgba(226, 226, 226, 0.3) 20%)' }}/>
        </div>
    );
};

export default Divider;