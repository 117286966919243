import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { faMoneyBills } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const FloatButton = () => {
    const [paginaAtual, setPaginaAtual] = useState('');

    useEffect(() => {
        const obterNomeDaPagina = () => {
            const url = window.location.pathname;
            const ultimaBarra = url.lastIndexOf('/');
            const nomeDaPagina = url.substring(ultimaBarra + 1);
            return nomeDaPagina;
        };

        const nomeDaPagina = obterNomeDaPagina();
        setPaginaAtual(nomeDaPagina);
    }, []);

    return (
        <div className="fixed inset-x-0 bottom-0 flex items-center justify-center pb-4 z-10">
            <div className="text-white rounded-full shadow-lg flex items-center space-x-2 w-3/4 md:w-1/2 lg:w-1/4 justify-between" style={{ backgroundColor: 'rgba(46, 46, 46, 1)', cursor: 'pointer' }}>
                <Link to={"/"}><FontAwesomeIcon icon={faHouse} className='text-2xl py-4 pl-6 pr-3' style={paginaAtual !== "" ? {color: 'rgba(168, 168, 168, 1)'} : {}} /></Link>
                <Link to={"/carrinho"}><FontAwesomeIcon icon={faCartShopping} className='text-2xl py-4 px-3' style={paginaAtual !== "carrinho" ? {color: 'rgba(168, 168, 168, 1)'} : {}} /></Link>
                <Link to={"/comanda"}><FontAwesomeIcon icon={faMoneyBills} className='text-2xl py-4 pl-3 pr-6' style={paginaAtual !== "comanda" ? {color: 'rgba(168, 168, 168, 1)'} : {}} /></Link>
            </div>
        </div>
    );
};

export default FloatButton;