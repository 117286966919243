import React from 'react';

const Categories = ({ categories, selectedCategory, onCategorySelect }) => {
    return (
        <div className='mt-4'>
            <div className="flex text-white overflow-y-hidden">
                {categories.map((category) => (
                    <div
                        key={category.id}
                        className='w-auto pr-4'
                        onClick={() => onCategorySelect(category)}>
                        <span className='text-lg font-normal' style={{ cursor: 'pointer', color: `${selectedCategory?.id === category.id ? 'rgba(255, 255, 255, 1)' : 'rgba(168, 168, 168, 1)'}` }}>
                            {category.name}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Categories;