import { useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import AuthService from "../services/AuthService";

function CodeTable() {
    const { restaurante, mesa } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        validarMesa();
    })

    async function validarMesa() {
        // setLoading(true);
        if(await AuthService.loginQrCode(restaurante, mesa))
            navigate("/");
    }

    return (
        <div className="text-white text-4xl">
            <h1>Carregando...</h1>
        </div>
    );
}

export default CodeTable;