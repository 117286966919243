import { useContext, useEffect, useState } from "react";
import Navbar from "../components/NavBar.js";
import PrimaryButton from "../components/PrimaryButton.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import CardProductFlat from "../components/CardProductFlat.js";
import CardValues from "../components/CardValues.js";
import FloatButton from "../components/FloatButton.js";
import { Link } from "react-router-dom";
import Modal from "../components/Modal.js";
import Divider from "../components/Divider.js";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { CartContext } from "../contexts/CartContext.js";
import axios from "axios";
import ClientData from "./ClientData.js";
import DeliveryData from "./DeliveryData.js";
import Loading from "./Loading.js";

function MyCart() {
    const [isModalConfirmCart, setIsModalConfirmCart] = useState(false);
    const [valorSubtotal, setValorSubtotal] = useState(0);
    const [valorTotal, setValorTotal] = useState(0);
    const { cart, clearCart } = useContext(CartContext);
    const [etapa, setEtapa] = useState(0);
    const [nomeCliente, setNomeCliente] = useState('');
    const [telefoneCliente, setTelefoneCliente] = useState('');
    const [loading, setLoading] = useState(false);

    const openModal = () => {
        setIsModalConfirmCart(true);
    };

    const closeModal = () => {
        setIsModalConfirmCart(false);
    };

    const transformarEmItemPedido = (objeto) => {
        return {
            id: 0,
            produtoId: objeto.id,
            quantidade: objeto.quantidade,
            precoUnitario: objeto.valor
        };
    };

    async function enviarPedido() {
        let balcao = localStorage.getItem('tipoCliente') === "delivery" ? false : true;
        let loadingBtn = false;
        if (balcao && !loadingBtn) {
            loadingBtn = true;
            setLoading(true);
            try {
                let itensPedido = cart.map(transformarEmItemPedido);
                let objetoParaEnviar = {
                    itensPedido: itensPedido,
                };

                const response = await axios.post(`Pedidos/PedidoBalcao`, objetoParaEnviar);
                if (response.status === 201) {
                    clearCart();
                    openModal();
                } else {
                    // msg erro
                }
            } catch (error) {
                // msg erro
            } finally {
                setLoading(false);
                loadingBtn = false;
            }
        }
        else {
            setEtapa(1);
        }
    };

    useEffect(() => {
        let totalCarrinho = 0;

        cart.map(produto => (
            totalCarrinho += ((produto.emPromocao ? produto.valorPromocional : produto.valor) * produto.quantidade)
        ))

        setValorSubtotal(totalCarrinho);
        setValorTotal(totalCarrinho);
    }, [cart]);

    const handleDeliveryDataSubmit = (data) => {
        if (data.tipoEntrega === "Delivery") {
            handleEnviarPedidoDelivery(data);
        }
        else if (data.tipoEntrega === "Retirada") {
            handleEnviarPedidoRetirada();
        }
    };

    const handleEnviarPedidoRetirada = async () => {
        let loadingBtn = false;
        if (!loadingBtn) {
            loadingBtn = true;

            if (cart.length === 0)
                return alert("Selecione um produto!");

            if (nomeCliente === "")
                return alert("Informe o nome do cliente!"); // msg erro

            if (telefoneCliente === "")
                return alert("Informe o telefone do cliente!"); // msg erro

            let produtosFormatados = cart.map(produto => ({
                ProdutoId: produto.id,
                Quantidade: produto.quantidade,
                Valor: produto.valor,
                Observacao: produto.observacao,
            }));

            localStorage.setItem('nomeCliente', nomeCliente);
            localStorage.setItem('telefoneCliente', telefoneCliente);

            try {
                setLoading(true);
                const response = await axios.post("Pedidos/AddRetirada", { Produtos: produtosFormatados, NomeCliente: nomeCliente, Telefone: telefoneCliente.replace(/\D/g, '') });
                if (response.status === 201) {
                    clearCart();
                    openModal();
                } else {
                    // msg erro
                }
            } catch (error) {
                // msg erro
            } finally {
                setLoading(false);
                loadingBtn = false;
            }
        }
    };

    const handleEnviarPedidoDelivery = async (data) => {
        if (cart.length === 0)
            return alert("Selecione um produto!"); // msg erro

        let produtosFormatados = cart.map(produto => ({
            ProdutoId: produto.id,
            Quantidade: produto.quantidade,
            Valor: produto.valor,
            Observacao: produto.observacao,
        }));

        let cliente = {
            nome: nomeCliente,
            telefone: telefoneCliente.replace(/\D/g, ''),
            logradouro: data.rua,
            numero: data.numero,
            bairro: data.bairro,
            cidade: data.cidade,
            cep: data.cep,
            estado: data.estado,
        }

        try {
            setLoading(true);
            const response = await axios.post("Pedidos/AddDelivery", { Produtos: produtosFormatados, Cliente: cliente });
            if (response.status === 201) {
                clearCart();
                openModal();
            } else {
                // msg erro
            }
        } catch (error) {
            // msg erro
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <>

            <Modal isOpen={isModalConfirmCart} onClose={closeModal}>
                <div className="text-white items-center flex text-center justify-center mb-4">
                    <FontAwesomeIcon className="text-4xl mr-4" icon={faCircleCheck} />
                    <span className="text-xl font-semibold">Pedido enviado!</span>
                </div>
                <Divider />
                <div className="my-4 text-white text-center p-4">
                    <span className="text-lg font-normal">Seu pedido foi confirmado! O tempo de prepado estimado é de 30 a 40 minutos</span>
                </div>
                <div>
                    <Link to={"/"}>
                        <PrimaryButton text={"Voltar para o Cardápio"} />
                    </Link>
                </div>
            </Modal>

            {etapa === 0 ?
                <div className="px-5 py-5 pb-20">
                    <Navbar typeNav="ReturnNav" title="Meu Carrinho" />

                    {cart.length === 0 ?
                        <div className="flex h-96 mt-48 justify-center items-center">
                            <div className="text-center px-6">
                                <FontAwesomeIcon icon={faUtensils} className='text-8xl text-white block w-full' style={{ color: 'rgba(168, 168, 168, 1)' }} />
                                <br />
                                <span className="text-white text-center text-xl" style={{ color: 'rgba(168, 168, 168, 1)' }}>Oops! Seu Carrinho está vazio. Explore nosso cardápio e escolha entre uma variedade de pratos deliciosos.</span>
                                <div className="my-4">
                                    <Link to={"/"}>
                                        <PrimaryButton text={"Voltar para o Cardápio"} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            {cart &&
                                cart.map(produto => (
                                    <CardProductFlat key={produto.id} produto={produto} />
                                ))
                            }

                            <CardValues obs="Tempo de Preparo: Entre 30 - 40 min" subtotal={valorSubtotal} total={valorTotal}>
                                <PrimaryButton text={"Enviar Pedido"} onClick={() => enviarPedido()} />
                            </CardValues>
                        </div>
                    }

                    <FloatButton />
                </div>
                : etapa === 1 ?
                    <ClientData
                        onNomeChange={(nome) => setNomeCliente(nome)}
                        onTelefoneChange={(telefone) => setTelefoneCliente(telefone)}
                        onContinuarClick={() => setEtapa(2)}
                    />
                    :
                    <DeliveryData onDeliveryDataSubmit={handleDeliveryDataSubmit} />
            }
        </>
    );
}

export default MyCart;