import { useEffect, useState } from "react";
import Navbar from "../components/NavBar.js";
import PrimaryButton from "../components/PrimaryButton.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";

function ClientData({ onNomeChange, onTelefoneChange, onContinuarClick }) {
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);
        if (onNomeChange) {
            onNomeChange(newName);
        }
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        preencherDados();
    }, []);

    function preencherDados(){
        let nome = localStorage.getItem('nomeCliente');
        let telefone = localStorage.getItem('telefoneCliente');
        setName(nome);
        onNomeChange(nome);
        setPhoneNumber(telefone);
        onTelefoneChange(telefone);
    }

    const onBtnContinuarClick = () => {
        // Msg erro
        if(name === "")
            return alert("Preencha seu nome corretamente");

        if(phoneNumber === "")
            return alert("Preencha seu telefone corretamente");

        onContinuarClick();
    };

    const handlePhoneChange = (e) => {
        let maskedValue = e.target.value.replace(/\D/g, '');

        if (maskedValue.length > 11) {
            maskedValue = maskedValue.substring(0, 11);
        }

        if (maskedValue.length > 10) {
            maskedValue = maskedValue.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        } else if (maskedValue.length > 6) {
            maskedValue = maskedValue.replace(/^(\d{2})(\d{4})/, '($1) $2-');
        } else if (maskedValue.length > 2) {
            maskedValue = maskedValue.replace(/^(\d{2})/, '($1) ');
        }

        setPhoneNumber(maskedValue);
        if (onTelefoneChange) {
            onTelefoneChange(maskedValue);
        }
    };

    return (
        <div className="px-5 py-5 pb-20">
            <Navbar typeNav="Delivery" title="Meus Dados" />
            <div className="flex justify-center">
                <div className="text-center px-6 mt-10">
                    <div className="flex flex-col text-left">
                        <label htmlFor="nomeCompleto" className="mb-1 text-white">Nome Completo:</label>
                        <input
                            type="text"
                            value={name}
                            onChange={handleNameChange}
                            id="nomeCompleto"
                            className="border rounded-[10px] p-2 w-full bg-transparent text-white"
                            style={{ borderColor: '#A8A8A8' }}
                        />
                    </div>
                    <div className="flex flex-col text-left mt-3 mb-10">
                        <label htmlFor="telefone" className="mb-1 text-white">WhatsApp:</label>
                        <input
                            type="text"
                            id="telefone"
                            value={phoneNumber}
                            onChange={handlePhoneChange}
                            placeholder="(XX) XXXXX-XXXX"
                            className="border rounded-[10px] p-2 w-full bg-transparent text-white"
                            style={{ borderColor: '#A8A8A8' }}
                        />
                    </div>
                    <br />
                    <FontAwesomeIcon icon={faUtensils} className="text-8xl text-white block w-full" style={{ color: '#A8A8A8' }} />
                    <br />
                    <span className="text-white text-center text-xl" style={{ color: '#A8A8A8' }}>
                        Quase lá! Informe seu nome e telefone para seguirmos com seu pedido. Depois, vamos escolher a forma de entrega.
                    </span>
                    <div className="my-4">
                        <PrimaryButton text="Continuar" onClick={() => onBtnContinuarClick()} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientData;
