import React from 'react';

const PrimaryButton = ({ text, onClick }) => {
    return (
        <button onClick={onClick} className="text-white font-semibold py-4 text-xl rounded-full w-full" style={{background: 'linear-gradient(to right bottom, #583500, #d17a00'}}>
            {text}
        </button>
    );
};

export default PrimaryButton;