import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const SearchBar = ({ onSearch }) => {
    const inputSearch = useRef(null);

    const clickSearch = () => {
        inputSearch.current.focus();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onSearch(inputSearch.current.value);
    };

    return (
        <div className='my-4'>
            <form onSubmit={handleSubmit}>
                <div
                    onClick={clickSearch}
                    className="text-white px-4 items-center rounded-[10px] flex h-14"
                    style={{ cursor: 'pointer', backgroundColor: 'rgba(0, 0, 0, 0.1)', boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)' }}
                >
                    <div className="items-center">
                        <FontAwesomeIcon icon={faMagnifyingGlass} className='text-2xl' style={{ marginRight: '15px' }} />
                    </div>
                    <div className="items-center w-full">
                        <input
                            ref={inputSearch}
                            type="text"
                            placeholder="Digite o nome do Produto"
                            className="text-lg bg-transparent border-none focus:outline-none w-full"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SearchBar;
