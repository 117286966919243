import axios from "axios";
import Routes from "./routes";
import authServiceInstance from "./services/AuthService";

axios.defaults.baseURL = "https://saasback.azurewebsites.net/";

function App() {
    authServiceInstance.setAxiosDefaultAuth();

    return (
        <>
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap" rel="stylesheet"></link>

            <Routes />
        </>
    );
}

export default App;