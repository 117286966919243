import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const QtdButton = ({ size, quantidade, setQuantidade }) => {

    const increaseQuantity = () => {
        if (quantidade < 99) {
            setQuantidade(quantidade + 1);
        }
    };

    const decreaseQuantity = () => {
        if (quantidade > 0) {
            setQuantidade(quantidade - 1);
        }
    };

    return (
        <div className={`my-2 flex items-center justify-center rounded-full ${size === "sm" ? 'space-x-2 p-1' : 'p-2 space-x-4'}`} style={{backgroundColor: 'rgba(217, 217, 217, 0.2)'}}>
            <button className="px-2 py-1 rounded-full" onClick={decreaseQuantity} style={{ backgroundColor: 'rgba(217, 217, 217, 0.4)'}}>
                <FontAwesomeIcon icon={faMinus} className='text-md text-white' />
            </button>
            <span className="text-2xl font-bold" style={{ color: '#FB9400' }}>
                {String(quantidade).padStart(2, '0')}
            </span>
            <button className="px-2 py-1 rounded-full" onClick={increaseQuantity} style={{ backgroundColor: 'rgba(217, 217, 217, 0.4)'}}>
                <FontAwesomeIcon icon={faPlus} className='text-md text-white' />
            </button>
        </div>
    );
};

export default QtdButton;