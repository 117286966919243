import axios from 'axios';

class AuthService {
    async loginQrCode(restaurante, mesa) {
        try {
            const response = await axios.get(`Auth/${restaurante}/${mesa}`);
            if (response.status === 200) {
                const data = await response.data;
                if (data.token) {
                    localStorage.setItem('jwt_access_token', JSON.stringify(data.token));
                    localStorage.setItem('tableNumber', mesa);
                    localStorage.setItem('tipoCliente', "balcao");
                    localStorage.removeItem('cart');
                    axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
                    return true;
                }
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    async login(restaurante) {
        try {
            const response = await axios.get(`Auth/${restaurante}`);
            if (response.status === 200) {
                const data = await response.data;
                if (data.token) {
                    localStorage.setItem('jwt_access_token', JSON.stringify(data.token));
                    localStorage.setItem('tipoCliente', "delivery");
                    localStorage.removeItem('cart');
                    axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
                    return true;
                }
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('cart');
        delete axios.defaults.headers.common['Authorization'];
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('jwt_access_token'));
    }

    setAxiosDefaultAuth() {
        const user = this.getCurrentUser();
        if (user) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${user}`;
        }
    }
}

const authServiceInstance = new AuthService();

export default authServiceInstance;