import React, { useContext, useEffect, useState } from 'react';
import QtdButton from './QtdButton';
import { formatValue } from '../utils/formatValue';
import { CartContext } from '../contexts/CartContext';
import Modal from "../components/Modal.js";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Divider from "../components/Divider.js";
import PrimaryButton from './PrimaryButton.js';
import SecondaryButton from './SecondaryButton.js';
import BadgeStatus from './BadgeStatus.js';

const CardProductFlat = ({ type, produto }) => {
    const [quantidade, setQuantidade] = useState(produto.quantidade);
    const [isModalRemoveCart, setIsModalRemoveCart] = useState(false);

    const { updateProductQuantity, removeFromCart } = useContext(CartContext);

    useEffect(() => {
        if (quantidade !== produto.quantidade) {
            if (quantidade === 0) {
                setQuantidade(1)
                openModal();
            } else {
                updateProductQuantity(produto.id, quantidade);
            }
        }
    }, [quantidade, produto.id, produto.quantidade, updateProductQuantity]);

    const openModal = () => {
        setIsModalRemoveCart(true);
    };

    const closeModal = () => {
        setIsModalRemoveCart(false);
    };

    return (
        <>
            <Modal isOpen={isModalRemoveCart} onClose={closeModal}>
                <div className="text-white items-center flex text-center justify-center mb-4">
                    <FontAwesomeIcon className="text-4xl mr-4" icon={faCircleQuestion} />
                    <span className="text-xl font-semibold">Tem certeza?</span>
                </div>
                <Divider />
                <div className="text-white text-xl p-2">
                    <span>Tem certeza que deseja remover {produto.titulo} do carrinho?</span>
                </div>
                <div className="flex p-2 space-x-6">
                    <SecondaryButton text={"Voltar"} onClick={() => closeModal()} />
                    <PrimaryButton text={"Remover"} onClick={() => removeFromCart(produto.id)} />
                </div>
            </Modal>

            <div className="flex items-center justify-center h-auto mt-4">
                <div className="flex justify-between w-full rounded-[10px] pr-1" style={{ background: 'linear-gradient(to right bottom, #282828, #424242' }}>
                    <div className="flex justify-center items-center">
                        <img src={produto.url_Imagem} alt="Hamburguer" className='w-24 h-24 h-lg-96 mr-4 object-cover rounded-[10px]' />
                        <div className="flex items-center">
                            <div>
                                {type !== "MyCard" ?
                                    <span className='block font-normal text-base' style={{ color: 'rgba(168, 168, 168, 1)' }}>{produto.tipo}</span>
                                    :
                                    <BadgeStatus status={produto.status} />
                                }
                                <span className='block font-semibold text-xl text-white'>{produto.titulo}</span>
                                {produto.emPromocao ?
                                    <div className="flex items-center space-x-2">
                                        <span className="font-light text-md text-white line-through">{formatValue(produto.valor)}</span>
                                        <span className="font-semibold text-2xl" style={{ color: '#FB9400' }}>{formatValue(produto.valorPromocional)}</span>
                                    </div>
                                    :
                                    <span className='font-semibold text-2xl' style={{ color: '#FB9400' }}>{formatValue(produto.valor)}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center">
                        {type !== "MyCard" ?
                            <div>
                                <QtdButton size='sm' quantidade={quantidade} setQuantidade={setQuantidade} />
                            </div>
                            :
                            <div className={`px-3 mr-2 flex items-center justify-center rounded-full space-x-2`} style={{ backgroundColor: 'rgba(217, 217, 217, 0.2)' }}>
                                <span className="text-white">
                                    Qtd:
                                </span>
                                <span className="text-xl font-bold" style={{ color: '#FB9400' }}>
                                    {quantidade && quantidade.toString().padStart(2, '0')}
                                </span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardProductFlat;