import React, { createContext, useState, useEffect } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);

    // Carregar o carrinho do localStorage quando o componente é montado
    useEffect(() => {
        const storedCart = localStorage.getItem('cart');
        if (storedCart) {
            setCart(JSON.parse(storedCart));
        }
    }, []);

    // Atualizar o localStorage sempre que o carrinho mudar
    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const addToCart = (product) => {
        const existingProduct = cart.find((item) => item.id === product.id);

        if (existingProduct) {
            const updatedCart = cart.map((item) =>
                item.id === product.id
                    ? {
                        ...item,
                        quantidade: item.quantidade + product.quantidade,
                        observacao: item.observacao
                            ? `${item.observacao}\n${product.observacao}`
                            : product.observacao,
                    }
                    : item
            );

            setCart(updatedCart);
        } else {
            setCart((prevCart) => [...prevCart, product]);
        }
    };

    const clearCart = () => {
        setCart([]);
    };

    const updateProductQuantity = (productId, quantity) => {
        setCart((prevCart) =>
            prevCart.map((item) =>
                item.id === productId ? { ...item, quantidade: quantity } : item
            )
        );
    };

    const removeFromCart = (productId) => {
        setCart((prevCart) =>
            prevCart.filter((item) => item.id !== productId)
        );
    };

    return (
        <CartContext.Provider value={{ cart, addToCart, updateProductQuantity, removeFromCart, clearCart }}>
            {children}
        </CartContext.Provider>
    );
};
