import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Navbar = ({ typeNav, title }) => {
    const tableNumber = localStorage.getItem('tableNumber');

    return (
        <asd>
            {typeNav.toUpperCase() === "HOME" ?
                <nav className="flex justify-between text-white">
                    <div className="items-center">
                        <span className="text-2xl font-normal block">
                            {(() => {
                                const nomeCompleto = localStorage.getItem('nomeCliente');
                                if (!nomeCompleto) return 'Olá';
                                const primeiroNome = nomeCompleto.split(' ')[0];
                                return `Olá, ${primeiroNome}`;
                            })()}
                        </span>
                        <span className="text-md" style={{ color: 'rgba(228, 228, 228, 0.8)' }}>O que sua fome pede hoje?</span>
                    </div>
                    <div className="flex items-center">
                        {tableNumber &&
                            <div className="font-light">
                                <span className='text-xl' style={{ color: 'rgba(228, 228, 228, 0.8)' }}>Mesa: </span>
                                <span className='text-xl'>{tableNumber}</span>
                            </div>
                        }
                    </div>
                </nav>
                :
                typeNav.toUpperCase() === "DELIVERY" ?
                    <nav className="flex justify-between text-white">
                        <div className="flex items-center">
                            <Link to={'/'} className="font-light text-center p-2 rounded-[5px]" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                                <FontAwesomeIcon icon={faArrowLeft} className='text-2xl' />
                            </Link>
                        </div>
                        <div className="flex items-center">
                            <div className="font-normal">
                                <span className='text-2xl'>{title}</span>
                            </div>
                        </div>
                        <div />
                    </nav>
                    :
                    <nav className="flex justify-between text-white">
                        <div className="flex items-center">
                            <Link to={'/'} className="font-light text-center p-2 rounded-[5px]" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                                <FontAwesomeIcon icon={faArrowLeft} className='text-2xl' />
                            </Link>
                        </div>
                        <div className="flex items-center">
                            <div className="font-normal">
                                <span className='text-2xl'>{title}</span>
                            </div>
                        </div>
                        <div className="flex items-center">
                            {tableNumber &&
                                <div className="font-light text-center">
                                    <span className='text-xl block' style={{ color: 'rgba(228, 228, 228, 0.8)' }}>Mesa: </span>
                                    <span className='text-xl'>{tableNumber}</span>
                                </div>
                            }
                        </div>
                    </nav>
            }
        </asd>
    );
};

export default Navbar;