import { Link } from 'react-router-dom';
import { formatValue } from '../utils/formatValue';
import { useContext } from "react";
import { ProductContext } from "../contexts/ProductContext.js";

const CardProduct = ({ badge, img, tipo, titulo, valor, productId, product }) => {
    const { setSelectedProduct } = useContext(ProductContext);

    return (
        <div className="flex items-center justify-center h-auto">
            <div className="bg-black w-full h-full rounded-[10px] relative">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 150" className='rounded-[10px]' preserveAspectRatio="none" style={{ height: "100%", width: "100%", position: "absolute", top: 0, left: 0 }}>
                    <path d="M-20,40 C100,100 180,30 500,60 L500,00 L0,0 Z" style={{ stroke: "none", fill: "#fff" }}></path>
                </svg>
                <div className="relative">
                    {badge !== undefined &&
                        <div className='text-white font-bold py-1 px-3 mb-2 inline-block absolute' style={{ backgroundColor: '#FB9400', borderBottomRightRadius: '10px', borderTopLeftRadius: '10px' }}>
                            {badge}
                        </div>
                    }
                    <div className="flex justify-center pb-5">
                        <img src={img} alt="Hamburguer" className='w-full h-48 h-lg-96 rounded-[10px] object-cover' />
                    </div>
                    <div className="px-4 pb-4">
                        <span className='block font-normal text-base' style={{ color: 'rgba(180, 180, 180, 1)' }}>{tipo}</span>
                        <span className='block font-extrabold text-xl text-white'>{titulo}</span>
                        {product.emPromocao ?
                            <div className="flex items-center space-x-2">
                                <span className="font-light text-md text-white line-through">{formatValue(valor)}</span>
                                <span className="font-medium text-2xl" style={{ color: '#FB9400' }}>{formatValue(product.valorPromocional)}</span>
                            </div>
                            :
                            <span className='font-medium text-2xl' style={{ color: '#FB9400' }}>{formatValue(valor)}</span>
                        }
                    </div>
                    <Link to={`/detalhes/${productId}`} onClick={() => setSelectedProduct(product)} className="absolute top-0 left-0 w-full h-full z-9"></Link>
                </div>
            </div>
        </div>
    );
};

export default CardProduct;