import React from 'react';

const SecondaryButton = ({ text, onClick }) => {
    return (
        <button onClick={onClick} className="text-white font-semibold py-4 text-xl rounded-full w-full" style={{border: '1px solid white'}}>
            {text}
        </button>
    );
};

export default SecondaryButton;