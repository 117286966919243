const PedidoStatus = {
    1: 'Pendente',
    2: 'Em Preparo',
    3: 'Pronto',
    4: 'Entregue',
    5: 'Cancelado'
};

export function getStatusName(statusNumber) {
    return PedidoStatus[statusNumber] || 'Desconhecido';
}