import React from 'react';
import CardProduct from './CardProduct'; // Import your CardProduct component

const CardGroup = ({ itens, categoria }) => {
    return (
        <div id={categoria} className='pt-4'>
            <span className='text-white text-2xl font-bold'>
                {categoria}
            </span>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 pt-4" >
                {itens.map((item) => (
                    <CardProduct key={item.id} badge={item.emPromocao ? "Promoção" : undefined} img={item.url_Imagem} tipo={item.tipo} titulo={item.titulo} valor={item.valor} productId={item.id} product={item} />
                ))}
            </div>
        </div>
    );
};

export default CardGroup;
