import React from 'react';
import { getStatusName } from '../utils/getNameStatus';

const BadgeStatus = ({ status }) => {
    const PedidoStatusColors = {
        1: '#FFA500', // Laranja para Pendente
        2: '#1E90FF', // Azul claro para Confirmado
        3: '#967522', // Amarelo para Em Preparo
        4: '#8A2BE2', // Azul violeta para Pronto
        5: '#008000', // Verde para Entregue
        6: '#FF0000'  // Vermelho para Cancelado
    };

    const statusName = getStatusName(status);
    const statusColor = PedidoStatusColors[status] || '#808080'; // Cinza para Desconhecido

    return (
        <span className='text-white rounded-[10px]' style={{backgroundColor: statusColor, padding: '1px 8px'}}>
            {statusName}
        </span>
    );
};

export default BadgeStatus;




// 