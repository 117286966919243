import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-50">
            <div className="w-5/6 md:w-2/6 p-6 rounded-[20px] shadow-lg" style={{ backgroundImage: "linear-gradient(to right bottom, #5a5a5a, #303030)" }}>
                {children}
            </div>
        </div>

    );
};

export default Modal;